import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlanTransportContainerJournal = _resolveComponent("PlanTransportContainerJournal")!
  const _component_PlanTransportBoxCarJournal = _resolveComponent("PlanTransportBoxCarJournal")!
  const _component_PlanTransportMotorJournal = _resolveComponent("PlanTransportMotorJournal")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.isShowTransportContainer)
      ? (_openBlock(), _createBlock(_component_PlanTransportContainerJournal, {
          key: 0,
          parentModel: _ctx.parentModel,
          isCalculation: _ctx.isCalculation,
          isSettingsOpen: _ctx.isSettingsOpen
        }, null, 8, ["parentModel", "isCalculation", "isSettingsOpen"]))
      : _createCommentVNode("", true),
    (_ctx.isShowTransportBoxCar)
      ? (_openBlock(), _createBlock(_component_PlanTransportBoxCarJournal, {
          key: 1,
          parentModel: _ctx.parentModel,
          isCalculation: _ctx.isCalculation,
          isSettingsOpen: _ctx.isSettingsOpen
        }, null, 8, ["parentModel", "isCalculation", "isSettingsOpen"]))
      : _createCommentVNode("", true),
    (_ctx.isShowTransportMotor)
      ? (_openBlock(), _createBlock(_component_PlanTransportMotorJournal, {
          key: 2,
          parentModel: _ctx.parentModel,
          isCalculation: _ctx.isCalculation,
          isSettingsOpen: _ctx.isSettingsOpen
        }, null, 8, ["parentModel", "isCalculation", "isSettingsOpen"]))
      : _createCommentVNode("", true)
  ]))
}