
import { defineComponent } from "vue";
import PlanTransportContainerJournal from "@/components/journals/PlanTransportJournal/PlanTransportContainerJournal.vue";
import PlanTransportBoxCarJournal from "@/components/journals/PlanTransportJournal/PlanTransportBoxCarJournal.vue";
import PlanTransportMotorJournal from "@/components/journals/PlanTransportJournal/PlanTransportMotorJournal.vue";
import { ability } from "@/abilities/ability";

export default defineComponent({
  components: {
    PlanTransportContainerJournal,
    PlanTransportBoxCarJournal,
    PlanTransportMotorJournal,
  },
  props: {
    parentModel: { required: true },
    isCalculation: { required: true },
    isSettingsOpen: { type: Object, required: true },
  },
  computed: {
    isShowTransportMotor() {
      return ability.can("view", "Modules.TransportMotor") && ability.can("view", "Documents.PlanTransportMotor");
    },
    isShowTransportContainer() {
      return ability.can("view", "Modules.TransportContainer") && ability.can("view", "Documents.PlanTransportContainer");
    },
    isShowTransportBoxCar() {
      return ability.can("view", "Modules.TransportBoxCar") && ability.can("view", "Documents.PlanTransportBoxCar");
    },
  },
  methods: {},
  data() {
    return {};
  },
});
